<template>
  <b-container class="p-4">
    <b-row>
      <b-col class="mb-2 d-flex align-items-center justify-content-between">
        <h4>Eligibility Attempts</h4>
        <b-button variant="primary" @click="download" :disabled="downloadInProgress">
          <b-spinner small v-if="downloadInProgress"/>
          Download CSV
        </b-button>
      </b-col>
    </b-row>
    <b-row class="d-flex align-items-center mb-4" @keyup.enter="applyFilters">
      <b-col>
        <b-form-group label="Client"
                      label-for="input-1">
          <b-form-select id="input-1"
                         v-model="filters.client_id"
                         :disabled="loading"
                         :options="filters.data.clients"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Account UUID"
                      label-for="input-4">
          <b-form-input id="input-4"
                        v-model="filters.account_uuid"
                        type="text"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="First Name"
                      label-for="input-3">
          <b-form-input id="input-3"
                        v-model="filters.first_name"
                        type="text"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Last Name"
                      label-for="input-3">
          <b-form-input id="input-3"
                        v-model="filters.last_name"
                        type="text"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Email"
                      label-for="input-4">
          <b-form-input id="input-4"
                        v-model="filters.email"
                        type="text"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Birthdate"
                      label-for="input-6">
          <b-form-input id="input-6"
                        placeholder="MM-DD-YYYY"
                        pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                        v-model="filters.birthdate"
                        type="date"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Exclusion Reason"
                      label-for="input-8">
          <b-form-select id="input-8"
                         v-model="filters.excluded_reason"
                         :disabled="loading"
                         :options="filters.data.excluded_reasons"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col style="max-width: 150px">
        <b-button variant="primary" @click="applyFilters">Search</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-table class="member-list"
              :fields="tableFields"
              :items="list.items"
              show-empty empty-text="No attempts found">
        <template #cell(birthdate)="data">
          <span>{{ formatDate(data.value, 'MM-DD-YYYY') }}</span>
        </template>
        <template #cell(created_at)="data">
          <span>{{ formatDate(data.value, 'MMMM DD YYYY, hh:mm') }}</span>
        </template>
        <template #cell(client_id)="data">
          <router-link v-if="$isSudo" :to="{ name: 'EditClient', params: { clientID: data.value } }" target="_blank">
            <span>{{ getClientName(data.value) || '-' }}</span>
          </router-link>
          <span v-else>{{ getClientName(data.value) || '-' }}</span>
        </template>
        <template #cell(more)="data">
          <div class="d-flex justify-content-center align-items-center">
            <b-button variant="primary"
                      class="ml-0"
                      size="sm"
                      style="min-width: 50px"
                      @click="selectItem(data.item)"
                      v-b-modal.modal-details>More
            </b-button>
          </div>
        </template>
      </b-table>
      <infinite-loading class="w-100" ref="infiniteLoader" @infinite="infiniteHandler">
        <template v-slot:no-results>
          <div class="d-flex justify-content-center flex-row align-items-center">
            <feather class="no-results-icon" type="meh"></feather>
            <h5 style="margin-left: 10px;">&nbsp;</h5>
          </div>
        </template>
        <template v-slot:no-more>&nbsp;</template>
        <template v-slot:error>&nbsp;</template>
      </infinite-loading>
    </b-row>
    <b-modal id="modal-details" size="lg">
      <div class="d-flex flex-column" slot="modal-title">
        <span>
          {{ `${getClientName(selectedItem.client_id)} - ${selectedItem.first_name} ${selectedItem.last_name}` }}
        </span>
        <small>Account UUID: {{ selectedItem.account_uuid }}</small>
        <div class="d-flex flex-row mt-2">
          <b-overlay :show="impersonationInProgress" rounded opacity="0.6" spinner-small>
            <b-button v-if="hasAccount" class="mr-1" variant="primary" @click="impersonate">
              Enroll for member
            </b-button>
          </b-overlay>
          <b-overlay :show="passwordResetInProgress" rounded opacity="0.6" spinner-small>
            <b-button v-if="hasAccount" class="mr-1" variant="primary" @click="sendPasswordResetEmail">
              Send password reset email
            </b-button>
            <router-link class="btn btn-success" v-if="hasAccount"
              :to="`/ops/search-member?account_uuid=${selectedItem.account_uuid}`">
              See account details
            </router-link>
          </b-overlay>
        </div>
      </div>
      <div class="card mb-4">
        <div class="card-header">
          General Information
        </div>
        <div class="card-body">
          <b-row class="mb-3">
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>ASO Group</b>
                <span title="Download multi-tenant file to check the ID">{{ selectedItem.aso_group || '-' }}</span>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Is Eligible</b>
                <span v-if="selectedItem.is_eligible === 1">Yes</span>
                <span v-else>No</span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="card mb-4">
        <div class="card-header">
          Address
        </div>
        <div class="card-body">
          <b-row class="mb-3">
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>State</b>
                <span>{{ selectedItem.geographical_state || '-' }}</span>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Zip Code</b>
                <span>{{ selectedItem.zip_code || '-' }}</span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="card mb-4">
        <div class="card-header">
          Subscriber &amp; Dependent
        </div>
        <div class="card-body">
          <b-row class="mb-3">
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Member Type</b>
                <span>{{ selectedItem.coverage_type || '-' }}</span>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Subscriber ID</b>
                <span v-if="selectedItem.identification !== '-'">Yes</span>
                <span v-else>No</span>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              <div class="d-flex flex-column justify-content-center">
                <b>Subscriber Name</b>
                <span v-if='selectedItem.subscriber'>
                  {{ selectedItem.subscriber.first_name }} {{ selectedItem.subscriber.last_name }}
                </span>
                <span v-else>-</span>
              </div>
            </b-col>
            <b-col>
              <div v-if='selectedItem.subscriber' class="d-flex flex-column justify-content-center">
                <b>Subscriber DOB</b>
                <span>{{ selectedItem.subscriber.birthdate || '-' }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col>
              <div v-if='selectedItem.payer' class="d-flex flex-column justify-content-center">
                <b>Health insurance provider</b>
                <span>{{ selectedItem.payer.name || '-' }}</span>
              </div>
            </b-col>
            <b-col>
              <div v-if='selectedItem.payer' class="d-flex flex-column justify-content-center">
                <b>Insurance number</b>
                <span>{{ selectedItem.identification || '-' }}</span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import moment from 'moment-timezone';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import * as R from 'ramda';

export default {
  name: 'ListEligibilityAttempts',
  data() {
    return {
      selectedItem: {},
      loading: false,
      filters: {
        client_id: '',
        first_name: '',
        last_name: '',
        email: '',
        birthday: '',
        account_uuid: '',
        excluded_reason: '',
        data: {
          clients: [
            { value: '', text: 'All (Default)' },
          ],
          excluded_reasons: [
            { value: '', text: 'All (Default)' },
          ],
          impersonationInProgress: false,
          passwordResetInProgress: false,
        },
      },
      tableFields: [
        { key: 'updated_at', label: 'Date' },
        { key: 'client_id', label: 'Client' },
        { key: 'account_uuid', label: 'Account UUID' },
        { key: 'first_name', label: 'First Name' },
        { key: 'last_name', label: 'Last Name' },
        { key: 'email', label: 'Email' },
        { key: 'birthdate', label: 'DOB (MM-DD-YYYY)' },
        { key: 'ui_app_version', label: 'UI Version' },
        { key: 'excluded_reason', label: 'Exclusion Reason' },
        { key: 'more', label: '' },
      ],
      list: {
        page: 0,
        limit: 100,
        items: [],
        total: 0,
      },
      users: {},
      downloadInProgress: false,
    };
  },
  components: {
    InfiniteLoading,
  },
  async beforeMount() {
    await this.loadFilters();
  },
  computed: {
    ...mapGetters({
      environmentConfigs: 'Core/getEnvironmentConfigs',
    }),
    hasAccount() {
      return this.selectedItem?.account_uuid !== '-';
    },
  },
  methods: {
    formatDate(date, format, tz) {
      let parsedDate = moment(date);
      if (tz) {
        parsedDate = moment.tz(date, tz);
      } else {
        parsedDate.utcOffset(date);
      }

      if (!parsedDate.isValid()) {
        return '-';
      }

      return parsedDate.format(format);
    },
    getClientName(clientId) {
      const client = this.filters.data.clients.find(c => c.value === clientId);
      if (client) {
        return client.text;
      }

      return '-';
    },
    async loadFilters() {
      const { data } = await axios.get('v1/onboarding/eligibility-attempts/filters');

      data.clients.forEach(client => {
        this.filters.data.clients.push({ value: client.id, text: `${client.display_name} (ID: ${client.id})` });
      });

      data.excluded_reasons.forEach(reason => {
        this.filters.data.excluded_reasons.push({ text: reason, value: reason });
      });
    },
    async impersonate() {
      try {
        this.impersonationInProgress = true;
        if (!this.selectedItem.account_uuid) {
          this.$noty.error('Member must create an account first');
          return;
        }

        const configs = this.environmentConfigs ?? {};

        const onboardingURL = configs.onboarding_url.replace(/\/$/, '');

        if (!onboardingURL) {
          this.$noty.error('Unable to find a valid onboarding URL');
          return;
        }

        const { data } = await axios.post('v1/onboarding/members/impersonation/generate', { account_id: this.selectedItem.account_uuid });
        window.open(`${onboardingURL}/impersonate/${data.token}`, '_blank');
      } catch (err) {
        console.error('Failed to impersonate: ', err);
        this.$noty.error('An unknown error ocurred, please try again');
      } finally {
        this.impersonationInProgress = false;
      }
    },
    async sendPasswordResetEmail() {
      if (this.passwordResetInProgress) {
        return;
      }

      try {
        this.passwordResetInProgress = true;
        const accountUuid = this.selectedItem?.account_uuid;
        if (!accountUuid) {
          this.$noty.error('Member must create an account first');
          return;
        }

        await axios.post('v1/onboarding/members/password-recover', { account_id: accountUuid });
        this.$noty.success('Password recover email sent', { timeout: 750, progressBar: false });
      } catch (err) {
        console.error('Failed to trigger password reset email: ', err);
        this.$noty.error('An unknown error ocurred, please try again');
      } finally {
        this.passwordResetInProgress = false;
      }
    },
    async loadAttemptList() {
      const filters = Object.assign({}, this.appliedFilters);
      filters.page = this.list.page;
      filters.limit = this.list.limit;

      const queryParams = new URLSearchParams(filters).toString();
      const { data } = await axios.get(`v1/onboarding/eligibility-attempts?${queryParams}`);
      return data;
    },
    applyFilters() {
      this.list.page = 0;
      this.list.limit = 100;
      this.list.items = [];
      this.list.total = 0;

      const { data, ...filtersWithoutData } = this.filters;
      this.appliedFilters = filtersWithoutData;
      Object.entries(this.appliedFilters).forEach(([ filterName, value ]) => {
        if (value == null || value === '') {
          delete this.appliedFilters[filterName];
        }
      });

      this.$refs.infiniteLoader.stateChanger.reset();
    },
    infiniteHandler($state) {
      this.loadAttemptList().then(data => {
        this.list.items = this.list.items.concat(this.formatAPIResponseItems(data.items));
        this.list.total = data.total;
        this.list.page += 1;
        if (data.items.length) {
          $state.loaded();
          return;
        }
        $state.complete();
      }).catch(err => {
        console.error(err);
        $state.error();
      });
    },
    formatAPIResponseItems(items) {
      return items.map(item => R.map(x => x || '-', item));
    },
    requestUserInfo(userId) {
      axios.get(`v1/users/${userId}`).then(({ data }) => {
        Vue.set(this.users, userId, data.user);
      }).catch(err => {
        console.error(err);
      });
    },
    selectItem(item) {
      this.selectedItem = item;
    },
    getUserFirstLastName(userId) {
      const user = this.users[userId];
      if (!user) {
        return 'Unknown';
      }

      return `${user.firstname} ${user.lastname}`;
    },
    async download() {
      this.downloadInProgress = true;
      try {
        const queryParams = new URLSearchParams(this.appliedFilters).toString();
        const { data } = await axios.get(`v1/onboarding/eligibility-attempts/export?${queryParams}`);

        const fileName = `export_eligibility_attempts_${moment().utc().format('YYYYMMDD_HHmmss')}.csv`;
        this.downloadFile(data, fileName);
      } catch (err) {
        if (err && err.response && err.response.data && err.response.data.message) {
          this.$noty.error(err.response.data.message);
        } else {
          this.$noty.error('Error downloading');
        }
      } finally {
        this.downloadInProgress = false;
      }
    },
    downloadFile(content, fileName) {
      const fileURL = URL.createObjectURL(new Blob([ content ]), { type: 'octet/stream' });
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', fileName);
      document.body.appendChild(fileLink);

      fileLink.click();
      URL.revokeObjectURL(fileLink.href);
      document.body.removeChild(fileLink);
    },
  },
};
</script>

<style>
.member-list {
  font-size: 15px;
}
</style>
